import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { ContentContainer, MarkdownHandler } from '@system'
import classNames from 'classnames'
import ACC_THEME from '@themes'

const useStyles = makeStyles((theme) => {
  return {
    background: ({
      backgroundImage,
      bgImage,
      isDarkGreyVariant,
      isDarkGreyPricingVariant,
      topOffset,
      isCopyLeftVariant,
    }) => ({
      backgroundColor: isDarkGreyVariant
        ? theme.palette.background.darkGrey
        : isDarkGreyPricingVariant
        ? theme.palette.background.slate
        : theme.palette.primary.main,
      backgroundImage:
        !!backgroundImage && !bgImage && `url(${backgroundImage.filename})`,
      backgroundSize: 'cover',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        backgroundColor:
          topOffset && isCopyLeftVariant && theme.palette.background.slite,
      },
    }),
    backgroundImage: ({ isCopyLeftVariant, topOffset }) => ({
      height: '100%',
      left: '0',
      position: 'absolute',
      right: '0',
      top: '0',
      width: '100%',
      zIndex: topOffset ? '2' : '0',
      '& > div': {
        height: '100%',
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        height: topOffset && `calc(100% + ${topOffset}px)`,
        margin: topOffset && '0 auto',
        maxWidth: topOffset && '1276px',
        transform: topOffset && `translateY(-${topOffset}px)`,
        '& img': {
          objectPosition: topOffset && 'top right !important',
        },
      },
      [theme.breakpoints.down('md')]: {
        '& img': {
          objectPosition: isCopyLeftVariant && 'bottom right !important',
        },
      },
    }),
    banner: ({ isDarkGreyVariant, secondaryCTA }) => ({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: isDarkGreyVariant ? '96px 0' : '48px 0',
      position: 'relative',
      zIndex: '1',
      [theme.breakpoints.up('md')]: {
        flexDirection: !secondaryCTA && 'row',
      },
    }),
    bannerCopyLeftVariant: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '96px 0',
      position: 'relative',
      zIndex: '3',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: '760px',
      },
    },
    ctaSubheadContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    headlineWithLink: {
      color: theme.palette.text.primary,
      fontSize: '1.6rem',
      marginRight: 'auto',
      '& h4, & h5': {
        margin: '0',
      },
      '& p': {
        fontWeight: ({ isDarkGreyVariant }) =>
          theme.typography[
            isDarkGreyVariant ? 'fontWeightBold' : 'fontWeightLight'
          ],
        marginTop: '8px',
      },
      width: '100%',
      textAlign: ({ isSingleRowVariant }) => !isSingleRowVariant && 'center',
    },
    headline: {
      color: theme.palette.text.primary,
      fontSize: '1.6rem',
      marginRight: 'auto',
      textAlign: 'center',
      width: '100%',
      '& h4, & h5': {
        margin: '0',
      },
      '& p': {
        fontWeight: ({ isDarkGreyVariant, isDarkGreyPricingVariant }) =>
          theme.typography[
            isDarkGreyVariant || isDarkGreyPricingVariant
              ? 'fontWeightBold'
              : 'fontWeightLight'
          ],
        marginTop: '8px',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '24px',
        textAlign: 'left',
        width: ({ isDarkGreyVariant, isDarkGreyPricingVariant }) =>
          isDarkGreyVariant || isDarkGreyPricingVariant ? '65%' : '80%',
      },
    },
    headlineCopyLeftVariant: {
      color: theme.palette.text.primary,
      fontSize: '28px',
      fontWeight: ACC_THEME.typography.fontWeightExtraBold,
      margin: '0',
    },
    headlineGreyVariant: {
      color: theme.palette.common.white,
      display: 'inline',
      fontSize: '1.5rem',
      fontWeight: theme.typography.fontWeightBold,
    },
    button: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down('md')]: {
        marginRight: ({ isSingleRowVariant }) => !isSingleRowVariant && '1rem',
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '1.5rem',
      },
    },
    inlineButton: ({ isCopyLeftVariant }) => ({
      alignItems: 'center',
      display: 'flex',
      fontWeight: theme.typography.fontWeightBold,
      gap: '24px',
      '& button': {
        '& span': {
          fontWeight: ({ isDarkGreyPricingVariant }) =>
            isDarkGreyPricingVariant ? '500' : 'bold',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: isCopyLeftVariant && 'column',
      },
    }),
    link: {
      '& a': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&:hover': {
          borderColor: theme.palette.common.white,
        },
      },
    },
    displayBlock: {
      color: theme.palette.common.white,
      display: 'block',
    },
    outerContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    icon: {
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px 0',
      },
    },
  }
})

const CtaBannerModule = ({ blok }) => {
  const {
    link,
    headline,
    button,
    variant,
    icon,
    backgroundImage,
    bgImage,
    topOffset,
  } = blok

  const isDarkGreyPricingVariant = variant === 'darkGreyPricing'
  const isDarkGreyVariant = variant === 'darkGrey'
  const isCopyLeftVariant = variant === 'copyLeft'
  const isSingleRowVariant = variant === 'singleRow'
  const secondaryCTA = !!link && link.length > 0
  const classes = useStyles({
    backgroundImage,
    bgImage,
    isCopyLeftVariant,
    isDarkGreyPricingVariant,
    isDarkGreyVariant,
    isSingleRowVariant,
    secondaryCTA,
    topOffset,
  })

  const renderCtaBannerModuleWithLink = () => (
    <Box className={classes.banner}>
      {icon?.filename && (
        <img src={icon.filename} alt={icon.alt} className={classes.icon} />
      )}
      <Box m={'auto'} className={classes.headlineWithLink}>
        <MarkdownHandler
          className={classNames({
            [classes.displayBlock]: !isDarkGreyVariant,
          })}
        >
          {headline}
        </MarkdownHandler>
      </Box>
      <Box className={classes.ctaSubheadContainer}>
        {button?.length > 0 && (
          <Box className={classes.button}>{renderBloks(button)}</Box>
        )}
        {link?.length > 0 && (
          <div className={classes.link}>{renderBloks(link)}</div>
        )}
      </Box>
    </Box>
  )

  return (
    <SbEditable content={blok}>
      <Box className={classes.background}>
        {bgImage?.length > 0 && (
          <div className={classes.backgroundImage}>{renderBloks(bgImage)}</div>
        )}
        <ContentContainer>
          {secondaryCTA ? (
            renderCtaBannerModuleWithLink()
          ) : (
            <Box
              className={classNames({
                [classes.banner]: !isCopyLeftVariant,
                [classes.bannerCopyLeftVariant]: isCopyLeftVariant,
              })}
            >
              {icon?.filename && (
                <img
                  src={icon.filename}
                  alt={icon.alt}
                  className={classes.icon}
                />
              )}
              <Box className={classes.headline}>
                <MarkdownHandler
                  className={classNames({
                    [classes.displayBlock]: !isDarkGreyVariant,
                    [classes.headlineGreyVariant]: isDarkGreyVariant,
                    [classes.headlineCopyLeftVariant]: isCopyLeftVariant,
                  })}
                >
                  {headline}
                </MarkdownHandler>
              </Box>
              {button?.length > 0 && (
                <Box className={classes.inlineButton}>
                  {renderBloks(button)}
                </Box>
              )}
            </Box>
          )}
        </ContentContainer>
      </Box>
    </SbEditable>
  )
}

export default CtaBannerModule
